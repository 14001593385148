import React from 'react'
import styled from 'styled-components';

const InputElement = styled.input`
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  border-radius: 3.1rem;
  outline: none;
  background-color: transparent;
  padding: 0.8rem 1rem 0.7rem 1rem;
  display: block;
  width: 100%;
  box-sizing: border-box;
  font-size: 1.6rem;
  line-height: 1.8rem;
  font-weight: 600;
  color: white;
  margin: 0.3rem 0;
  &::placeholder {
    color: ${({ theme }) => theme.colors.primary};
    font-size: 0.9rem;
    line-height: 1.4rem;
  }
  &:focus {
      outline: none;
      background-color: rgba(255, 255, 255, 0.02);
  }
`

const TextareaElement = styled(InputElement)`
    min-height: 5rem;
`

const Input = (props) => {
  if(props.element === 'input') {
    return (
      <InputElement
        onChange={props.changed}
        placeholder={props.placeholder}
        required={props.required}
        type={props.type}
        value={props.value}
        min={props.min}
        max={props.max}
        step="any"
      />
    )
  }

  if(props.element === 'textarea') {
    return (
        <TextareaElement
          as="textarea"
          onChange={props.changed}
          placeholder={props.placeholder}
          required={props.required}
        />
    )
  }
}

export default Input;